import firebase from 'firebase';

export type InstaAsset = {
  id?: string,
  caption?: string,
  media_url?: string,
  username?: string,
  media_type?: "IMAGE" | "VIDEO" | "CAROUSEL_ALBUM",
  children?: { data: InstaAsset[] }
}

export type InstaAssetCache = {
  uid: string
  data: InstaAsset[]
  time: number
  index: number
}

export type AccessToken = {
  id: string // doc id
  type: "insta" | "unknown"
  uid: string // HAMONI UID
  key: string | null // insta: Client side key
  user_id: string | null // insta: user_id
  token: string // insta: user_token
  created_at: number | null // timestamp
}

type ArgsState = {
  uid: string | null
  key: string | null
}

type ApiResult<T> = {
  data: T[],
  paging?: {
    cursors?: {
      before?: string,
      after?: string
    }
    next?: string
  }
}

const db = firebase.firestore();
const instaAssets: InstaAsset[] = [];

const InstaService = () => {

  const getTokens = async (uid: string) => {
    const snap = await db.collection("tokens")
        .where("uid", "==", uid)
        .where("type", "==", "insta")
        .get().catch((e)=>{ console.warn(e); return null })
    const tokens = snap?.docs
      .map(d => d.data() as AccessToken)
      .sort((a, b) => (b.created_at ?? 0) - (a.created_at ?? 0 ) ) ?? []; // desc
    console.log("InstaSv> Synced token...", tokens.length)
    return tokens;
  }

  /** Insta上のメディアリストを取得します。トークンが存在しない場合エラーをスローします。(reqUrl: Next pointer url.)*/
  const getMediaList = async (token: string, nextUrl?: string) => {
    const uri = nextUrl ?? `https://graph.instagram.com/me/media?fields=caption,media_url,username,children&access_token=${token}`
    console.log("InstSv> ", uri);
    const res = await fetch(uri)
    const data = await res.json() as ApiResult<InstaAsset>
    console.log("InstSv> Geted...", data.data.length)
    return data;
  }

  /** InstAssetの不足している部分(詳細)をチェック、取得します。 */
  const getAssetDetail = async(token: string, ass: InstaAsset) => {
    if(!ass.id || !token) return ass;
    const a = ass.id ? instaAssets.find(i => i.id == ass.id) : null
    if(a) return a;
    if(ass.children?.data && ass.children.data.length > 0){
      const children = await Promise.all(ass.children.data.slice(1).map(async a => { //　index[0]は親投稿とおなじなのでスキップ
        const uri = `https://graph.instagram.com/${a.id}?fields=media_url,media_type&access_token=${token}`
        console.log("InstSv> ", uri);
        const res = await fetch(uri)
        const data = await res.json() as InstaAsset
        return data;
      }))
      console.log("InstSv> All Geted... Children", children.length)
      const _ass: InstaAsset = { ...ass, children: { data: children.filter(c => c.media_type == "IMAGE") } } // VIDEO REMOVE...
      instaAssets.push(_ass)
      return _ass;
    }
    return ass;
  }

  /** リクエストを送信します */
  const syncMediaRequest = async(uid: string) => {
    const uri = `https://app.hamoni.jp/api/admin/instamedia?uid=${uid}`
    const data = await (await fetch(uri)).json()
    return data;
  }

  const getInstaAssetCache = async(uid: string) => {
    const assets = await db.collection("instaAssetCaches")
      .where("uid", "==", uid)
      .orderBy("time", "desc")
      .get()
      .then(snap => snap.docs.map(d => d.data() as InstaAssetCache ))
      .catch(e => {
        console.log(e);
        return [];
      })
    
    var time = assets?.[0]?.time ?? 0;
    var data: InstaAsset[] = [];

    const baseDatas = assets.filter(a => a.time == time);
    const _datas = [...baseDatas].sort((a, b) => a.index - b.index) ;

    _datas.forEach(d => data = [...data, ...d.data]);
    
    // for(var i = 0; i < assets.length; i++){
    //   if(time != assets[i].time) break;
    //   data = [...data, ...assets[i].data];
    // }

    return data;
  }

  return {
    getTokens,
    getMediaList,
    getAssetDetail,
    syncMediaRequest,
    getInstaAssetCache
  }

};

export default InstaService;
