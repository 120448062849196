import React from "react";
import Header from "../component/Header";
import { fetchOnAdmin } from "../utils/admin-fetch-utils";
import { createShopRequest } from "../service/AdminAPIService";

const ShopPage: React.FC = () => {
  
  const [isBusy, setIsBusy] = React.useState(false)

  const [addr, setAddr] = React.useState("日本、〒123-4567 東京都")
  const [name, setName] = React.useState("")
  const [lat, setLat] = React.useState("")
  const [lng, setLng] = React.useState("")
  const [gopen, setGOpen] = React.useState<GOpen[]>([])

  // optional
  const [tel, setTel] = React.useState("")
  const [website, setWebsite] = React.useState("")
  
  // 使用可能
  const [shop, setShop] = React.useState<{[K in keyof Shop]?: Shop[K]}>({})

  const onPushSearch = () => {

    const data: CreateNewShopRequest = {
      addr, name, location: { lat: Number.parseInt(lat) || 1, lng: Number.parseInt(lng) || 1 }, gopen, 
      shop: { ...shop, website: website != "" ? website : undefined, tel: tel != "" ? tel : tel } // optional
    }
    console.log("Will be create...")
    console.table(data)

    if(!window.confirm("店舗作成すると、本番環境に即座に店舗が登録されます。\n本当に続行しますか？")) return
    if(isBusy) return
    setIsBusy(true)
    createShopRequest(data)
    .then(r => {
      if(r?.status == "success"){
        console.log("Created.", r)
        alert(`成功しました。pid: ${r.shop?.pid} shopid: ${r.shop?.id}`)
      }
      else alert("エラーが発生しました；；\n" + r?.message)
    })
    .catch(e => null)
    .finally(() => setIsBusy(false))
  }

  return (
    <article style={{ position: "relative" }}>
      <Header current="user" />
      <p style={{ fontSize: "small", padding: 5 }}>Shopを新規作成します。</p>
      <div style={{ padding: "0 10px" }}>
        <div style={{ marginTop: 8 }}>
          <label>* addr</label>
          <input type="text" value={addr} onChange={(e) => setAddr(e.target.value)}/>
          <p>※Googleフォーマット: 日本、〒123-4567 東京都...</p>
        </div>

        <div style={{ marginTop: 8 }}>
          <label>* name</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)}/>
        </div>

        <div style={{ marginTop: 8 }}>
          <label>* lat / lng</label>
          <input type="text" value={lat} onChange={(e) => setLat(e.target.value)}/>
          <input type="text" value={lng} onChange={(e) => setLng(e.target.value)}/>
          <p>※無い場合、空欄で入力 自動補完(1,1)</p>
        </div>

        <div style={{ marginTop: 8 }}>
          <label>(optional) tel</label>
          <input type="text" value={tel} onChange={(e) => setTel(e.target.value)}/>
        </div>

        <div style={{ marginTop: 8 }}>
          <label>(optional) website</label>
          <input type="text" value={website} onChange={(e) => setWebsite(e.target.value)}/>
        </div>

        <input type="button" value={"送信"} onClick={onPushSearch}/>

        <p style={{padding: 5, borderBottom: "1px solid lightgray"}}></p>

        <section style={{ padding: "10px 5px" }}>
          { isBusy ? <img src="/loading.svg"/> : <div />}
        </section>

      </div>
    </article>
  );
};

export default ShopPage;
