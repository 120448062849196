import React from "react";
import Header from "../component/Header";

const Embed: React.FC = () => {
  const [isBusy, setIsBusy] = React.useState(true);

  return (
    <article style={{ position: "relative" }}>
      <Header current="suggestion" />
      <div
        style={{
          position: "absolute",
          top: 30,
          left: 0,
          width: "100%",
          height: "calc(100% - 30px)",
          backgroundColor: "black",
          display: isBusy ? "flex" : "none",
          opacity: 0.5,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p style={{ color: "white" }}>Loading...</p>
      </div>
      <p>hello world</p>
    </article>
  );
};

export default Embed;
