import React from "react";
import Header from "../component/Header";

const _getKey = () => {
  const chars = 'xxxxxxxxxxxxxxx'.split(''); // 15
  for (let i = 0, len = chars.length; i < len; i++)
    chars[i] = Math.floor(Math.random() * 16).toString(16);

  return 'k' + chars.join('');
};

const INSTA_AUTH_BASE = `https://api.instagram.com/oauth/authorize?client_id=525684098971871&scope=user_profile,user_media&response_type=code&redirect_uri=${encodeURIComponent("https://asia-northeast1-hamoni-jp.cloudfunctions.net/api-registerInstagramToken")}`

const InstaDirectToken: React.FC = () => {
  
  const [uid, setUid] = React.useState("")
  const [key, setKey] = React.useState(_getKey())
  const [redirect, setRedirect] = React.useState("https://")

  const [url, setUrl] = React.useState("")

  React.useEffect(() => {
    if(uid){
      // リクエスト用のデータを作成
      const data = {
        uid,
        key,
        redirect
      };
      // URIを生成する
      setUrl(`${INSTA_AUTH_BASE}&state=${encodeURIComponent(JSON.stringify(data))}`);
    }else{
      setUrl(""); // 無し
    }

  }, [uid])

  const data = JSON.stringify({ uid, key, redirect })

  const copyClipBoardUrl = () => {
    if(typeof(window) !== undefined){
      window.navigator.clipboard.writeText(url)
        .then(r => alert("クリップボードにコピーしました。"))
        .catch(e => alert("クリップボードを使用できません。" + e))
    }
  }

  return (
    <article style={{ position: "relative" }}>
      <Header current="instalink" />
      <p style={{ fontSize: "small", padding: 5 }}>UIDを元に連携リンクを作成します。</p>
      <div style={{ padding: "0 10px" }}>
        <div style={{ marginTop: 8 }}>
          <label>UID</label>
          <input value={uid} type="text" onChange={(e) => setUid(e.target.value)}/>
        </div>

        <div style={{ marginTop: 8 }}>
          <label>Key</label>
          <input value={key} type="text" onChange={(e) => setKey(e.target.value)}/>
        </div>

        <div style={{ marginTop: 8 }}>
          <label>Redirect</label>
          <input value={redirect} type="text" onChange={(e) => setRedirect(e.target.value)}/>
        </div>

        <p style={{padding: 5, borderBottom: "1px solid lightgray"}}></p>

        <div style={{ marginTop: 8 }}>
          <p>リンク</p>
          <input type="text" disabled value={url} style={{ minWidth: 320 }}/>
          <input type="button" value={"Copy"} onClick={copyClipBoardUrl}/>
          <p>
          { url ? <a href={url} >{url}</a> : <div/> }
          </p>
        </div>

        <p style={{padding: 5, borderBottom: "1px solid lightgray"}}></p>

        <div style={{ marginTop: 8 }}>
          <p>※ユーザーページ</p>
          <textarea disabled style={{ width: 300, height: 100 }} value={data} />
        </div>


      </div>
    </article>
  );
};

export default InstaDirectToken;
