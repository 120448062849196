import firebase from 'firebase';
import "firebase/firestore";

firebase.initializeApp({
    apiKey: "AIzaSyCPnPXdyfWYPiDh7oknbPa2QiCM0iDpabY",
    authDomain: "hamoni-jp.firebaseapp.com",
    databaseURL: "https://hamoni-jp.firebaseio.com",
    projectId: "hamoni-jp",
    storageBucket: "hamoni-jp.appspot.com",
    messagingSenderId: "291257975850",
    appId: "1:291257975850:web:14d955c9a4b4914c4f2f53",
    measurementId: "G-L344FZMDYR"
});