import React from "react";
import InstaService, { InstaAsset } from "../service/InstaService";
import { pushInsaPost, InstaPost, Mark } from "../service/HAdminService";
import SearchShop, { SearchShopSelectResult, Handler } from "./SearchShop";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Hotkeys from "react-hot-keys";
import LoadingOverlay from "react-loading-overlay-ts";
import { getStrMatchRate } from "../levenshteinDistance";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";

type Props = {
  uid: string;
  asset: InstaAsset;
  addMarkId: (mark: Mark) => void;
  defaultMark?: Mark
  onClose?: () => void;
};

type PriceItem = {
  price: string;
  selected: boolean;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Component: React.FC<Props> = ({ asset, uid, addMarkId, defaultMark, onClose }) => {
  let watchingClipboardTimer = React.useRef<NodeJS.Timer>(null!);
  let prevwClipboard = "";

  const images: (string | undefined)[] = [asset.media_url];
  if (asset.children)
    images.push(...asset.children.data.map((a) => a.media_url));

  // const [post, setPost] = React.useState<InstaPost>({
  //     pid: "",
  //     shopid: null,
  //     createUserId: uid,
  //     images: [] // will override
  // })

  const [isLoading, setIsLoading] = React.useState(false);
  const [content, setContent] = React.useState(asset.caption);
  const [price, setPrice] = React.useState("");
  const [prices, setPrices] = React.useState<PriceItem[]>([]);
  const [shop, setShop] = React.useState<SearchShopSelectResult>();
  const [ratio, setRatio] = React.useState<Ratio | null>(null);

  const [isEditableContent, setIsEditableContent] = React.useState(false);
  const [ignoreImagesIndex, setIgnoreImagesIndex] = React.useState<number[]>(defaultMark?.selectedIndex ?? [])
  const [isVisibleSuccessModal, setIsVisibleSuccessModal] = React.useState(false);

  // ダイアログ用
  const [open, setOpen] = React.useState(false);
  const [shopSearchQ, setShopSearchQ] = React.useState("");
  const [shopEreaSearch, setShopEreaSearch] = React.useState("");
  const [shopAddressSearchQ, setShopAddressSearchQ] = React.useState("");
  const [shopSearchQDirection, setShopSearchQDirection] = React.useState<
    "down" | "right" | "up" | "left"
  >("right");
  const [shopAddressSearchQDirection, setShopAddressSearchQDirection] =
    React.useState<"down" | "right" | "up" | "left">("right");
  const [shopSearchQIndex, setShopSearchQIndex] = React.useState(1);
  const [shopAddressSearchQIndex, setShopAddressSearchQIndex] =
    React.useState(1);
  const [watchingClipboard, setWatchingClipboard] = React.useState(false);

  const searchShopRef = React.useRef({} as Handler);

  const hashtags: string[] = getTagByWord(content).tags;

  React.useEffect(() => {
    setContent(asset.caption);
    // setIgnoreImagesIndex(defaultMark?.selectedIndex ?? [])
    setIgnoreImagesIndex(defaultMark?.selectedIndex ? images.map((i, ix) => ix) : []) // ミス防止の為に選択済み項目がある投稿は一度フォーカスをすべて外す
    setIsEditableContent(false)
    if (images?.[0]) getImgSize(images[0]).then((r) => setRatio(r));
  }, [asset]); // 選択する投稿を変更したときのeffect

  /** Post[]を送信します。 */
  const onPushPost = async () => {
  if (!shop?.pid) return console.error("PID NotFound.");
    const _price = Number(price);

    setIsLoading(true);

    const _images = images
      .filter((i, ix) => i && ignoreImagesIndex.find(_i => ix === _i) === undefined) as string[] // undefinedを除く + ignoreされてない項目

    if(_images.length <= 0) return console.error("Not Selected Image.")

    const r = await pushInsaPost({
      createUserId: uid,
      shopid: null,
      pid: shop.pid,
      images: _images, // images.filter((i) => i) as string[],
      content,
      hashtags: shop.name ? [shop.name, ...hashtags] : hashtags,
      price: _price ? _price : null,
      ratio,
    })
    .catch(() => {
      alert("エラーが発生しました");
      return null;
    });

    if (r && asset.id) {
      if(ignoreImagesIndex.length > 0){ // 選択投稿
        const selectedIndex = images
          .map((i, ix) => ignoreImagesIndex.findIndex(_i => _i === ix) >= 0 ? null : ix) // ignoreに指定されていたら除く(null)
          .filter(i => i !== null) as number[]

        if(defaultMark?.mark == "uploaded_parts" && defaultMark.selectedIndex){ // 既に選択投稿されてた(結合する必要がある)
          const marged = [
            ...selectedIndex, 
            ...defaultMark.selectedIndex.filter(i => selectedIndex.findIndex(_i => i === _i) < 0) // 重複削除
          ]
          console.log("@", ignoreImagesIndex,selectedIndex, defaultMark.selectedIndex)
          console.log(marged)
          if(marged.length === images.length)
            addMarkId({ id: asset.id, mark: "uploaded" }) // 全ての画像をマークした
          else
            addMarkId({ id: asset.id, mark: "uploaded_parts", selectedIndex: marged }) // 部分的投稿をした 
        } else {
          addMarkId({ id: asset.id, mark: "uploaded_parts", selectedIndex }) 
        }
      } else { // 選択投稿では無い
        addMarkId({ id: asset.id, mark: "uploaded" }); 
      }
    }

    console.log("[OK] Request pushed.", asset.id);
    setIsLoading(false);
    clearInput();
    showSuccessAlert();

    onClose?.();
  };

  const getImgSize = (src: string) => {
    return new Promise<Ratio>((x) => {
      const img = new Image();
      img.src = src;
      const onload: ((this: GlobalEventHandlers, ev: Event) => any) | null = (
        e
      ) => {
        x({
          width: img.width,
          height: img.height,
        });
      };
      img.onload = onload;
      img.style.display = "none";
    });
  };

  const onPushMarkSkip = () => {
    if (!asset.id) return;
    addMarkId({ id: asset.id, mark: "skipped" });
    clearInput();
    onClose?.();
  };

  /**
   * 入力項目をリセットする
   */
  const clearInput = () => {
    setPrice("");
    setShop(undefined);
    searchShopRef.current.reset();
  };

  /**
   * 成功のアラートを表示する
   */
  const showSuccessAlert = () => {
    setIsVisibleSuccessModal(true);

    setTimeout(() => {
      setIsVisibleSuccessModal(false);
    }, 3000);
  };

  React.useEffect(() => {
    setPrices([]);

    if (content) {
      // const prices1 = content
      //   .match(/[¥￥]((([1-9]\d*)(,\d{3})*)|0)/g)
      //   ?.map((val) => {
      //     return {
      //       price: val,
      //       selected: true,
      //     };
      //   });
      // const prices2 = content
      //   .match(/((([1-9]\d*)(,\d{3})*)|0)円/g)
      //   ?.map((val) => {
      //     return {
      //       price: val,
      //       selected: true,
      //     };
      //   });
      // let allPrices: PriceItem[] = [...(prices1 ?? []), ...(prices2 ?? [])];
      // setPrices(allPrices);
      // calcTotalPrice(allPrices);
    }

    const rows = content?.split(/\n/);

    const shopQ = getSearchObject("shopSearchQ");
    const shopAddressQ = getSearchObject("shopAddressSearchQ");
    let searchWord = "";

    if (shopQ != null && shopQ.text !== "" && rows) {
      for (let i = 0; i < rows.length; i++) {
        const index = rows[i].indexOf(shopQ.text);
        if (index >= 0) {
          if (shopQ.direction === "down") {
            searchWord +=
              rows[Math.min(i + shopQ.index, rows.length - 1)] + " ";
          } else if (shopQ.direction === "up") {
            searchWord += rows[Math.max(i - shopQ.index, 0)] + " ";
          } else if (shopQ.direction === "left") {
            searchWord += rows[i].substr(0, index) + " ";
          } else {
            searchWord += rows[i].substr(index + shopQ.text.length) + " ";
          }
          break;
        }
      }
    }

    if (shopAddressQ != null && shopAddressQ.text !== "" && rows) {
      for (let i = 0; i < rows.length; i++) {
        const index = rows[i].indexOf(shopAddressQ.text);
        if (index >= 0) {
          if (shopAddressQ.direction === "down") {
            searchWord +=
              rows[Math.min(i + shopAddressQ.index, rows.length - 1)];
          } else if (shopAddressQ.direction === "up") {
            searchWord += rows[Math.max(i - shopAddressQ.index, 0)];
          } else if (shopAddressQ.direction === "left") {
            searchWord += rows[i].substr(0, index);
          } else {
            searchWord += rows[i].substr(index + shopAddressQ.text.length);
          }
          break;
        }
      }
    }

    searchWord = searchWord
      .replaceAll("＆", "アンド")
      .replaceAll("&", "アンド");

    if (shopEreaSearch !== "") {
      searchWord = searchWord + shopEreaSearch;
    }

    let tempSearchWord = searchWord.replace(/#/g, "");
    searchShopRef.current.setSearchWord(tempSearchWord);
  }, [content]); // ! hook 確認 (文字変更可能に)

  const onClickPriceItem = (index: number) => {
    const _prices = prices.map((item, index2) => {
      if (index === index2) {
        item.selected = !item.selected;
        return item;
      } else {
        return item;
      }
    });

    setPrices(_prices);
    calcTotalPrice(_prices);
  };

  const calcTotalPrice = (prices: PriceItem[]) => {
    let total = 0;
    prices.forEach((item) => {
      if (item.selected) {
        const numPrice = parseInt(item.price.replace(/[,¥￥円]/g, ""));
        total += numPrice;
      }
    });
    if (total === 0) {
      setPrice("");
    } else {
      setPrice(total.toString());
    }
  };

  const saveDialogSearchWord = () => {
    const shopSearchQObject: SearchQ = {
      text: shopSearchQ ?? "",
      direction: shopSearchQDirection,
      index: shopSearchQIndex,
    };
    const shopAddressSearchQObject: SearchQ = {
      text: shopAddressSearchQ ?? "",
      direction: shopAddressSearchQDirection,
      index: shopAddressSearchQIndex,
    };

    localStorage.setItem("shopSearchQ", JSON.stringify(shopSearchQObject));
    localStorage.setItem(
      "shopAddressSearchQ",
      JSON.stringify(shopAddressSearchQObject)
    );
    sessionStorage.setItem(
      "watchingClipboard",
      watchingClipboard ? "true" : "false"
    );
    setOpen(false);
  };

  const onPressCBShortCutKey = async (keyName: string) => {
    console.log(keyName);
    if (keyName === "f") {
      if (!isLoading) {
        onPushPost();
      }
    } else if (keyName === "s") {
      if (!isLoading) {
        onPushMarkSkip();
      }
    } else if (keyName === "c") {
      const text = window?.getSelection()?.toString();
      if (text) {
        let tempText = text.replace(/#/g, "");
        searchShopRef.current.setSearchWord(tempText);
      }
    } else if (keyName === "v") {
      const text = await navigator.clipboard.readText();
      if (text) {
        searchShopRef.current.setSearchWord(text);
      }
    } else if (keyName === "a") {
      searchShopRef.current.setIsActiveGApi(true);
      setTimeout(() => {
        searchShopRef.current.search();
      }, 800);
    }
  };

  const onPushPickedImage = (url: string | undefined, index: number) => {
    if(!url) return console.warn("this image not have url.")
    if(ignoreImagesIndex.findIndex(i => i == index) < 0) {
      if(ignoreImagesIndex.length <= 0) setIgnoreImagesIndex(images.map((i, ix) => ix).filter(i => i != index)) // 一つ目はそれ以外を選択する
      else setIgnoreImagesIndex([...ignoreImagesIndex, index]) // 登録
    }
    else setIgnoreImagesIndex(ignoreImagesIndex.filter(i => i != index)) // 解除
  }

  console.log("render. ignore: ", ignoreImagesIndex)

  React.useEffect(() => {
    if (shop) {
      const rate = getStrMatchRate(
        searchShopRef.current.getSearchWord(),
        shop.name + shop.addr
      );

      if (rate >= 60 && !isLoading) {
        onPushPost();
      }
    }
  }, [shop]);

  React.useEffect(() => {
    if (open) {
      try {
        const shopSearchQObject = getSearchObject("shopSearchQ");
        const shopAddressSearchQObject = getSearchObject("shopAddressSearchQ");

        if (shopSearchQObject) {
          setShopSearchQ(shopSearchQObject.text);
          setShopSearchQDirection(shopSearchQObject.direction);
          setShopSearchQIndex(
            Number.isFinite(shopSearchQObject.index)
              ? Number(shopSearchQObject.index)
              : 1
          );
        } else {
          setShopSearchQ("");
          setShopSearchQDirection("right");
          setShopSearchQIndex(1);
        }

        if (shopAddressSearchQObject) {
          setShopAddressSearchQ(shopAddressSearchQObject.text);
          setShopAddressSearchQDirection(shopAddressSearchQObject.direction);
          setShopAddressSearchQIndex(
            Number.isFinite(shopAddressSearchQObject.index)
              ? Number(shopAddressSearchQObject.index)
              : 1
          );
        } else {
          setShopAddressSearchQ("");
          setShopAddressSearchQDirection("right");
          setShopAddressSearchQIndex(1);
        }
      } catch (error) {
        console.log("こっちですーーーReact.useEffect");
        localStorage.removeItem("shopSearchQ");
        localStorage.removeItem("shopAddressSearchQ");
        setShopSearchQ("");
        setShopAddressSearchQ("");
        setShopSearchQDirection("right");
        setShopAddressSearchQDirection("right");
        setShopSearchQIndex(1);
        setShopAddressSearchQIndex(1);
      }
    }
  }, [open]);

  React.useEffect(() => {
    if (watchingClipboard && watchingClipboardTimer.current == null) {
      watchingClipboardTimer.current = setInterval(() => {
        navigator.clipboard.readText().then((text) => {
          if (text !== prevwClipboard) {
            prevwClipboard = text;
            searchShopRef.current.setSearchWord(text);
          }
        });
      }, 100);
    } else {
      clearInterval(watchingClipboardTimer.current);
      watchingClipboardTimer.current = null!;
    }
  }, [watchingClipboard]);

  React.useEffect(() => {
    // Clear any running intervals on component unmount
    return () => clearInterval(watchingClipboardTimer.current);
  }, []);

  const getSearchObject = (key: string) => {
    try {
      const objectStr = localStorage.getItem(key);
      const object: SearchQ | null = objectStr ? JSON.parse(objectStr) : null;

      return object;
    } catch (error) {
      console.log("ここのエラーgetSearchObject");
      localStorage.removeItem("shopSearchQ");
      localStorage.removeItem("shopAddressSearchQ");
      setShopSearchQ("");
      setShopAddressSearchQ("");
      setShopSearchQDirection("right");
      setShopAddressSearchQDirection("right");
      setShopSearchQIndex(1);
      setShopAddressSearchQIndex(1);

      return null;
    }
  };

  return (
    <Hotkeys
      keyName="f,s,c,v,a"
      onKeyUp={(keyName) => onPressCBShortCutKey(keyName)}
    >
      <LoadingOverlay active={isLoading} spinner text="投稿中...">
        <nav>
          {isVisibleSuccessModal && (
            <Alert
              style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
              severity="success"
            >
              投稿完了しました
            </Alert>
          )}

          <div className="titleBanner">
            <p className="titleBanner__title">PREVIEW</p>
            <input style={{appearance: "checkbox", margin: 5 }} type="checkbox" checked={isEditableContent} onChange={e => setIsEditableContent(e.target.checked)}/>
            <label style={{color: "white", fontSize: "small"}}>文字の編集</label>

            <button onClick={() => setOpen(true)}>設定</button>

          </div>

          {/* コメント */}
          <textarea
            className="commentTextArea"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            disabled={!isEditableContent}
          />

          {/* 正規表現で取得した値段のループ */}
          <p className="pricesTitle">コメント内の値段</p>
          <div className="prices">
            {prices.map((item, index) => {
              return (
                <p
                  onClick={() => onClickPriceItem(index)}
                  className={
                    item.selected
                      ? "prices__item"
                      : "prices__item prices__item--inactive"
                  }
                  key={`${index}`}
                >
                  {item.price}
                </p>
              );
            })}
          </div>

          <section className="columnItem searchColumnItem">
            {/* 値段 */}
            <div className="columnItem">
              <label>合計金額</label>
              <input
                type="text"
                value={price}
                maxLength={6}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>

            {/* 店舗 */}
            <div className="columnItem">
              <label>店舗： {shop ? shop.name : "未選択"}</label>
            </div>
          </section>

          <SearchShop
            ref={searchShopRef}
            onSelectShop={setShop}
            selectedPid={shop?.pid}
          />

          <div className="buttonErea">
            {/* 送信 */}
            <button className="sendBtn" onClick={onPushPost}>
              <span>投稿する【f】</span>
            </button>

            {/* スキップ */}
            <div className="skipBtn">
              <input type="button" value="skip【s】" onClick={onPushMarkSkip} />
            </div>

            <Tooltip title="オンにすると、クリップボードの変更を検知し、変更されると検索ワードに自動的に設定されます">
              <IconButton
                onClick={() => setWatchingClipboard(!watchingClipboard)}
              >
                <ContentPasteGoIcon
                  color={watchingClipboard ? "action" : "disabled"}
                />
              </IconButton>
            </Tooltip>
          </div>

          <div className="titleBanner">
            <p className="titleBanner__title">選択中の画像</p>
          </div>

          <section className="selectImages">
            {images.map((i, ix) => {
              return (
                <div style={{position: "relative"}}>
                  <img
                    src={`${i}`}
                    style={{
                      width: 100,
                      height: 100,
                      backgroundColor: "gray",
                      objectFit: "cover",
                    }}
                    key={ix}
                    onClick={() => onPushPickedImage(i, ix)}
                    className={ignoreImagesIndex.findIndex(_i => _i == ix) >= 0 ? "disable" : undefined}
                  />
                  { defaultMark?.selectedIndex?.find(_ix => _ix === ix) !== undefined ?
                    <img
                      className="icon"
                      src="/icon/files.png" 
                    />
                    : <div />
                  }
                </div>
              );
            })}
          </section>

          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            TransitionComponent={Transition}
          >
            <div className="dialog">
              <div className="dialog__row">
                <TextField
                  label="エリアで絞る"
                  variant="outlined"
                  className="dialog__input"
                  value={shopEreaSearch}
                  onChange={(e) => setShopEreaSearch(e.target.value)}
                />
              </div>

              <div className="dialog__row">
                <TextField
                  label="お店検索ワード"
                  variant="outlined"
                  className="dialog__input"
                  value={shopSearchQ}
                  onChange={(e) => setShopSearchQ(e.target.value)}
                />
                <div className="dialog__inputSmall">
                  <TextField
                    label="何行目"
                    type="number"
                    variant="outlined"
                    className="dialog__input"
                    value={shopSearchQIndex}
                    onChange={(e) =>
                      setShopSearchQIndex(Math.max(Number(e.target.value), 1))
                    }
                  />
                </div>
                <IconButton
                  size="large"
                  onClick={() => setShopSearchQDirection("down")}
                >
                  <ArrowCircleDownIcon
                    color={
                      shopSearchQDirection === "down" ? "action" : "disabled"
                    }
                  />
                </IconButton>
                <IconButton
                  size="large"
                  onClick={() => setShopSearchQDirection("right")}
                >
                  <ArrowCircleRightOutlinedIcon
                    color={
                      shopSearchQDirection === "right" ? "action" : "disabled"
                    }
                  />
                </IconButton>
                <IconButton
                  size="large"
                  onClick={() => setShopSearchQDirection("left")}
                >
                  <ArrowCircleLeftOutlinedIcon
                    color={
                      shopSearchQDirection === "left" ? "action" : "disabled"
                    }
                  />
                </IconButton>
                <IconButton
                  size="large"
                  onClick={() => setShopSearchQDirection("up")}
                >
                  <ArrowCircleUpOutlinedIcon
                    color={
                      shopSearchQDirection === "up" ? "action" : "disabled"
                    }
                  />
                </IconButton>
              </div>
              <div className="dialog__row">
                <TextField
                  label="住所検索ワード"
                  variant="outlined"
                  className="dialog__input"
                  value={shopAddressSearchQ}
                  onChange={(e) => setShopAddressSearchQ(e.target.value)}
                />
                <div className="dialog__inputSmall">
                  <TextField
                    label="何行目"
                    type="number"
                    variant="outlined"
                    className="dialog__input"
                    value={shopAddressSearchQIndex}
                    onChange={(e) =>
                      setShopAddressSearchQIndex(
                        Math.max(Number(e.target.value), 1)
                      )
                    }
                  />
                </div>
                <IconButton
                  size="large"
                  onClick={() => setShopAddressSearchQDirection("down")}
                >
                  <ArrowCircleDownIcon
                    color={
                      shopAddressSearchQDirection === "down"
                        ? "action"
                        : "disabled"
                    }
                  />
                </IconButton>
                <IconButton
                  size="large"
                  onClick={() => setShopAddressSearchQDirection("right")}
                >
                  <ArrowCircleRightOutlinedIcon
                    color={
                      shopAddressSearchQDirection === "right"
                        ? "action"
                        : "disabled"
                    }
                  />
                </IconButton>
                <IconButton
                  size="large"
                  onClick={() => setShopAddressSearchQDirection("left")}
                >
                  <ArrowCircleLeftOutlinedIcon
                    color={
                      shopAddressSearchQDirection === "left"
                        ? "action"
                        : "disabled"
                    }
                  />
                </IconButton>
                <IconButton
                  size="large"
                  onClick={() => setShopAddressSearchQDirection("up")}
                >
                  <ArrowCircleUpOutlinedIcon
                    color={
                      shopAddressSearchQDirection === "up"
                        ? "action"
                        : "disabled"
                    }
                  />
                </IconButton>
              </div>
              <div className="dialog__row">
                <Button
                  variant="contained"
                  onClick={() => saveDialogSearchWord()}
                >
                  Contained
                </Button>
              </div>
            </div>
          </Dialog>
        </nav>
      </LoadingOverlay>
    </Hotkeys>
  );
};

// type OnLoadImgProps = {
//   onLoad: (w: number, h: number) => void
//   src?: string
// }

// const OnLoadImg: React.FC<OnLoadImgProps> = ({onLoad, src}) => {
//   if(src){
//     const img = new Image();
//     img.src = src;
//     const onload: ((this: GlobalEventHandlers, ev: Event) => any) | null = (e) => { console.log("HELLO", e)}
//     img.onload = onload;
//   }
//   return (
//     <div />
//   )
// }

const getTagByWord = (str?: string) => {
  if (!str) return { tags: [], str };
  const tags: string[] = [];
  let isTag = false;
  let tag = "";
  let newStr = "";

  for (let s of str) {
    if (s == "#") {
      if (tag.length > 0) {
        tags.push(tag);
        tag = "";
      } else {
        isTag = true;
        tag = "";
      }
    } else if (s == " " || s == "\n") {
      tag.length > 0 && tags.push(tag);
      tag = "";
      isTag = false;

      newStr += s;
    } else if (isTag) tag = tag + s;
    else newStr += s;
  }
  if (tag.length > 0) tags.push(tag);
  return { tags, str: newStr.trim() };
};

export default Component;
