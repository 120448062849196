import React from "react";

export type SearchShopSelectResult = {
  name: string;
  pid: string;
  addr?: string;
};

type HamoniPlacesResult = {
  pid: string;
  name: string;
  shopid?: string;
  addr?: string;
  thumbnail?: string;
};

type ApiHamoniPlacesResult = {
  status: "success" | "error";
  data: HamoniPlacesResult[];
  time: number;
};

type Props = {
  onSelectShop: (r: SearchShopSelectResult) => void;
  selectedPid?: string;
};

export interface Handler {
  reset: () => void;
  setSearchWord: (text: string) => void;
  getSearchWord: () => string;
  search: () => void;
  setIsActiveGApi: (isGApi: boolean) => void;
}

const SearchShop = React.forwardRef<Handler, Props>((props, ref) => {
  const { onSelectShop, selectedPid } = props;

  const { getPlaceResultsByGAPI, getPlaceResultsByHamoni } = Service();

  const [text, setText] = React.useState("");
  const [datas, setDatas] = React.useState<HamoniPlacesResult[]>([]);
  const [isSearching, setIsSearching] = React.useState(false);
  const [isActiveGApi, setIsActiveGApi] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    reset() {
      reset();
    },
    setSearchWord(text) {
      setText(text);
      if (text !== "") {
        onPushSearch(text);
      }
    },
    getSearchWord() {
      return text;
    },
    search() {
      // setIsActiveGApi(isGApi);
      onPushSearch();
      setIsActiveGApi(false);
    },
    setIsActiveGApi(isGApi) {
      setIsActiveGApi(isGApi);
    },
  }));

  const onPushSearch = async (val?: string) => {
    let _datas: HamoniPlacesResult[] = [];
    setIsSearching(true);

    if (val) {
      _datas = isActiveGApi
        ? await getPlaceResultsByGAPI(val)
        : await getPlaceResultsByHamoni(val);
    } else {
      _datas = isActiveGApi
        ? await getPlaceResultsByGAPI(text)
        : await getPlaceResultsByHamoni(text);
    }

    console.log(_datas);
    setDatas(_datas);
    setIsSearching(false);

    if (_datas?.length === 1) {
      onSelectShop({
        pid: _datas[0].pid,
        name: _datas[0].name,
        addr: _datas[0].addr,
      });
    }

    // setIsActiveGApi(false);
  };

  const reset = () => {
    setText("");
    setDatas([]);
  };

  const test_ivent = (e: any) => {
    if (e.keyCode === 13) {
      onPushSearch();

      console.log("クリック");
    }
  };

  return (
    <section className="searchShopContainer">
      {/* 検索エリア */}
      <div className="columnItem">
        <input
          type={"checkbox"}
          checked={isActiveGApi}
          onChange={(e) => setIsActiveGApi(e.target.checked)}
          style={{ WebkitAppearance: "checkbox", margin: 5 }}
        />
        <label style={{ fontSize: "small", color: "gray" }}>
          (非推奨) GoogleAPIを使用 ※API使用料金がかかります。
        </label>
      </div>

      <div className="columnItem">
        <p style={{ fontSize: "x-small", color: "lightgray" }}>
          ※ShopIDまたはPIDから検索することも出来ます。その場合GoogleAPIを使わず「shopid=xxx」のように検索します。
        </p>
      </div>
    
      <div className="columnItem">
        <input
          className="searchBtn"
          type="button"
          value="検索する"
          onClick={() => onPushSearch()}
        />
        <input
          className="searchInput"
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="店舗を検索"
          onKeyPress={(e) => e.nativeEvent.key === "Enter" && onPushSearch()}
        />

        <div style={{ width: 20, height: 20 }}>
          <img
            style={{ display: isSearching ? "flex" : "none" }}
            src="loading.gif"
          />
        </div>
        
      </div>

      {/* 検索結果を表示 */}
      <div className="searchResulttable">
        {datas.map((i, ix) => {
          return (
            <a
              style={{
                borderBottom: "1px solid gray",
                cursor: "pointer",
                backgroundColor:
                  selectedPid == i.pid ? "lightgray" : "whitesmoke",
              }}
              key={ix}
              onClick={() => onSelectShop({ pid: i.pid, name: i.name })}
            >
              <p style={{ fontWeight: "bold" }}>{i.name}</p>
              <p style={{ fontSize: "small" }}>{i.addr}</p>
            </a>
          );
        })}
      </div>
    </section>
  );
});

const Service = () => {
  /** HamoniからPlaces情報を取得します。 */
  const getPlaceResultsByHamoni = async (word: string) => {

    // 特殊検索から行う。
    // shopid=から始まる場合特殊検索する
    if(RegExp(/shopid=.+/).test(word)) {
      const shopid = word.replace(/shopid=/, "").trim()
      const shop = await _getShopByShopId(shopid)
        .then(r => r?.data ?? null)

      console.log("getPlaceResultsByHamoni / ShopID Search...", shopid, shop)
      if(shop) {
        return [{ pid: shop.pid, name: shop.name, shopid, addr: shop.addr }] as HamoniPlacesResult[]
      }
    } 
    // pid=から始まる場合特殊検索する
    else if (RegExp(/pid=.+/).test(word)){ 
      const pid = word.replace(/pid=/, "").trim()
      const shop = await _getShopByPid(pid)
        .then(r => r?.data ?? null)

      console.log("getPlaceResultsByHamoni / PID Search...", pid, shop)
      if(shop) {
        return [{ pid, name: shop.name, shopid: shop.id, addr: shop.addr }] as HamoniPlacesResult[]
      }
    }

    // 通常検索を行う
    let url = `https://test.app.hamoni.jp/api/?wd=${word}`;
    console.log("getPlaceResults> ApiHamoniPlace.", url);

    const response = await fetch(url).catch((e) => null);
    const json = (await response?.json()) as ApiHamoniPlacesResult | undefined;
    return json?.status == "success" ? json.data : [];
  };

  // AdminAPI: ShopIDからShopを取得する
  const _getShopByShopId = async (shopid: string) => {  
    return await fetch(`https://app.hamoni.jp/api/admin/shop/${shopid}`, {
      headers: {
        "Authorization": "hadmin_dev" // 簡易認証
      }
    })
    .then(async r => r.ok ? await r.json() as APIResultBaseSingle<Shop> : null)
    .catch(e => { console.warn(e); return null })
  }

  // AdminAPI: ShopIDからShopを取得する
  const _getShopByPid = async (pid: string) => {  
    return await fetch(`https://app.hamoni.jp/api/admin/pid/${pid}`, {
      headers: {
        "Authorization": "hadmin_dev" // 簡易認証
      }
    })
    .then(async r => r.ok ? await r.json() as APIResultBaseSingle<Shop> : null)
    .catch(e => { console.warn(e); return null })
  }

  /** GApiからPlaces情報を取得します。 */
  const getPlaceResultsByGAPI = async (word: string) => {
    let url = `https://app.hamoni.jp/api/g/place?word=${word}`;
    console.log("getPlaceResults> ApiGooglePlace.", url);

    const response = await fetch(url).catch((e) => null);
    const json = (await response?.json()) as ApiHamoniPlacesResult | undefined;
    return json?.status == "success" ? json.data : [];
  };

  return { getPlaceResultsByGAPI, getPlaceResultsByHamoni };
};

export default SearchShop;
