import React from "react";
import "../initializeFirebase";
import { addMarkId, updMarkId, getMarkIds, Mark } from "../service/HAdminService";
import InstaService, { AccessToken, InstaAsset } from "../service/InstaService";
import InputPost from "../component/InputPost";
import Header from "../component/Header";

const Home: React.FC = () => {
  const { getTokens, getAssetDetail, syncMediaRequest, getInstaAssetCache } =
    InstaService();
  const [token, setToken] = React.useState<AccessToken>();

  const [media, setMedias] = React.useState<InstaAsset[]>([]);
  const [result, setResult] = React.useState<string>();

  const [uid, setUid] = React.useState("");
  const [selectedAsset, setSelectedAsset] = React.useState<InstaAsset>();

  const [markIds, setMarkIds] = React.useState<Mark[]>(getMarkIds);

  //

  const [order, setOrder] = React.useState<"asc" | "desc" | string>("asc");
  const [filter, setFilter] = React.useState<
    "remain" | "uploaded" | "skipped" | "all" | string
  >("remain");

  const _datas = order === "asc" ? [...media].reverse() : media;
  let viewData: InstaAsset[] = _datas;
  switch (filter) {
    case "remain": // markに存在しないか updated_parts としてマークされている
      viewData = _datas.filter((d) =>{
        const mark = markIds.find((m) => m.id === d.id)
        if(!mark || mark?.mark == "uploaded_parts") return true;
        else return false;
      });
      break;
    case "uploaded":
      viewData = _datas.filter((d) =>
        markIds.find((m) => m.id === d.id && m.mark === "uploaded")
      );
      break;
    case "skipped":
      viewData = _datas.filter((d) =>
        markIds.find((m) => m.id === d.id && m.mark === "skipped")
      );
      break;
    case "all":
    default:
      break;
  }

  // hotkeys('command+b', function(event, handler){
  //     // Prevent the default refresh event under WINDOWS system
  //     event.preventDefault()
  //     console.log('you pressed F5!')
  // });

  //

  const _addMarkId = (mark: Mark) => {
    //addMarkId(mark);
    //setMarkIds([...markIds, mark]);
    setMarkIds(updMarkId(mark));
  };

  const onPushGetRequest = () => {
    syncMediaRequest(uid).then((r) => {
      setResult(JSON.stringify(r));
      setToken(undefined);
    });
  };

  const onPushGetMedias = () => {
    console.log("onPushGetMedias");
    getInstaAssetCache(uid).then((r) => setMedias(r ?? []));
  };

  const getToken = async () => {
    return await getTokens(uid).then((ts) => {
      if (ts[0]) {
        setToken(ts[0]);
        return ts[0];
      }
      return null;
    });
  };

  const onCloseInputPost = () => {
    const nowIx = viewData.findIndex((v) => v.id === selectedAsset?.id);
    const nextAsset = viewData?.[nowIx + 1];
    onSelectAsset(nextAsset);
  };

  const onSelectAsset = async (ass: InstaAsset) => {
    const _token = token ?? (await getToken());
    if (_token)
      getAssetDetail(_token.token, ass).then((a) => setSelectedAsset(a));
  };

  const screenWidth = window.innerWidth;
  const coulumnItem = () => {
    if (screenWidth > 600) {
      return 150;
    } else {
      return screenWidth / 4;
    }
  };

  return (
    <article style={{}}>
      <Header current="home" />
      <nav className="panel">
        <section className="itemPanel">
          {/* プレビュー画面 */}
          {selectedAsset ? (
            <InputPost
              uid={uid}
              asset={selectedAsset}
              addMarkId={_addMarkId}
              defaultMark={markIds.find(m => m.id == selectedAsset.id)}
              onClose={onCloseInputPost}
            />
          ) : (
            <p className="errorAlert">画像を選択してください</p>
          )}
          {/* アクセス情報を設定 */}
          <div className="titleBanner">
            <p className="titleBanner__title">アクセス情報を設定</p>
          </div>
          <section>
            {/* uidを検索 */}
            <div className="columnItem uidContainer">
              <label>UID: </label>
              <input
                className="uidInput"
                placeholder="UIDを入力してください"
                value={uid}
                onChange={(e) => setUid(e.target.value)}
                type="text"
                disabled={selectedAsset ? true : false}
              />
              <input
                className="uidSeachBtn"
                value="検索"
                onClick={onPushGetMedias}
                type="button"
              />
            </div>

            {/* 取得結果 */}
            <div className="columnItem">
              <label>取得結果: {result ?? "..."}</label>
              <input
                className="uidSeachBtn"
                value="取得"
                onClick={onPushGetRequest}
                type="button"
              />
            </div>
          </section>
        </section>

        <section className="itemPanel">
          <nav className="pullDownContainer">
            <select
              value={order}
              onChange={(e) => {
                setOrder(e.target.value);
                console.log(e.target.value);
              }}
            >
              <option value="asc">昇順</option>
              <option value="desc">降順</option>
            </select>
            <select
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
            >
              <option value="remain">残りの投稿</option>
              <option value="uploaded">送信済み投稿</option>
              <option value="skipped">マークされた投稿</option>
              <option value="all">全ての投稿</option>
            </select>
            <p className="count">残り：{viewData.length}</p>
          </nav>
          <section className="imageItems">
            {viewData.map((i, ix) => {
              return (
                <div className="gridItemContainer" key={ix} onClick={() => onSelectAsset(i)}>
                  <img
                    alt="AAA"
                    src={`${i.media_url}`}
                    style={{
                      borderRadius: 10,
                      margin: 5,
                      width: coulumnItem(),
                      height: coulumnItem(),
                      backgroundColor: "gray",
                      objectFit: "cover",
                      transform:
                        i.id === selectedAsset?.id ? "scale(1.05)" : "none",
                    }}
                  />
                  { markIds.find((m) => m.id === i.id) ?
                    <img
                      className="icon"
                      src="/icon/files.png" 
                    />
                    : <div />
                  }
                </div>
              );
            })}
          </section>
        </section>
      </nav>
      {/* <p style={{ fontSize: "x-small", margin: 8, color: "lightgray" }}>
        Version: 0.2.3
      </p> */}
    </article>
  );
};
export default Home;
