import React from "react";
import Header from "../component/Header";

const InstaDetail: React.FC = () => {
  
  const [isBusy, setIsBusy] = React.useState(false)
  const [uid, setUid] = React.useState("")
  const [user, setUser] = React.useState<InstagramUserInfo | null>(null)

  const onPushSearch = () => {
    if(isBusy) return
    setIsBusy(true)
    getInstaUserByUid(uid)
      .then(r => setUser(r?.data ?? null))
      .catch(e => null)
      .finally(() => setIsBusy(false))
  }

  const getInstaUserByUid = async (uid: string) => {  
    return await fetch(`https://app.hamoni.jp/api/admin/insta/user/${uid}`, {
      headers: {
        "Authorization": "hadmin_dev" // 簡易認証
      }
    })
    .then(async r => r.ok ? await r.json() as APIResultBaseSingle<InstagramUserInfo> : null)
    .catch(e => { console.warn(e); return null })
  }

  return (
    <article style={{ position: "relative" }}>
      <Header current="user" />
      <p style={{ fontSize: "small", padding: 5 }}>UIDを元にInstagramユーザを検索します。</p>
      <div style={{ padding: "0 10px" }}>
        <div style={{ marginTop: 8 }}>
          <label>UID</label>
          <input type="text" onChange={(e) => setUid(e.target.value)}/>
          <input type="button" value={"検索"} onClick={onPushSearch}/>
        </div>

        <p style={{padding: 5, borderBottom: "1px solid lightgray"}}></p>

        <section style={{ padding: "10px 5px" }}>
          { isBusy ? <img src="/loading.svg"/> : <div />}
          { user ? 
              <div>
                <h2>User</h2>
                <table rules="all" style={{ border: "1px solid lightgray" }} >
                  <tbody>
                    <tr>
                      <td>ユーザーID (uid)</td>
                      <td>{uid}</td>
                    </tr>
                    <tr>
                      <td>ユーザーID (instagram id)</td>
                      <td>{user.id}</td>
                    </tr>
                    <tr>
                      <td>ユーザー名 (instagram username)</td>
                      <td>{user.username}</td>
                    </tr>
                    <tr>
                      <td>メディア数 (instagram media_count)</td>
                      <td>{user.media_count}</td>
                    </tr>
                  </tbody>
                </table>
              </div> 
            : <p>Not Found</p>
          }
        </section>

      </div>
    </article>
  );
};

export default InstaDetail;
