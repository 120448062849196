type Marks = {
  data: Mark[]
}

export type Mark = {
  id: string
  mark?: "uploaded" | "uploaded_parts" | "skipped" // アップロード済み、一部済み、スキップ
  selectedIndex?: number[] | null // 選択した項目(uploaded_parts)
}

export type InstaPost = {
  pid: string
  shopid: string | null
  content?: string
  createUserId: string
  hashtags?: string[] | null
  price?: number | null
  images: string[]
  ratio?: Ratio | null
}

export const getMarkIds = () => {
  const marked = localStorage.getItem("marked");
  if(marked) return (JSON.parse(marked) as Marks).data ?? [];
  return []
}

/** Markを追加します。*/
export const addMarkId = (mark: Mark) => {
  const ids = getMarkIds();
  const data = { data: [...ids, mark] } as Marks;
  localStorage.setItem("marked", JSON.stringify(data));
  return data.data;
}

/** Markを追加します。同一idが存在した場合置換が行われます。 */
export const updMarkId = (mark: Mark) => {
  const ids = getMarkIds();
  const data = { data: [...ids.filter(id => id.id != mark.id), mark] } as Marks;
  localStorage.setItem("marked", JSON.stringify(data));
  return data.data;
}

/** マークを削除します。(ids: 対象) */
export const removeMarkId = (ids: string[]) => {
  const marked = getMarkIds();
  const marks =  marked.filter(i => !ids.includes(i.id));
  localStorage.setItem("marked", JSON.stringify({ data: marks } as Marks));
  return marks;
}

export const pushInsaPost = async (post: InstaPost) => {
  // const r = await fetch("https://api.app.hamoni.jp/admin/instapost", { 
  //   method: "post", 
  //   // mode: "cors",    
  //   // これ入れるとバグる (怒)
  //   // headers: {
  //   //   'Content-Type': 'application/json',
  //   // },
  //   headers: {
  //     'Accept': 'application/json',
  //     // 'Content-Type': 'application/json;charset=UTF-8',
  //   },
  //   body: JSON.stringify(post)
  // })
  console.log(JSON.stringify(post))

  await fetch("https://hadmin-task-runner.hamoni.jp/tasks", { 
    method: "post", 
    headers: {
      'Accept': 'application/json',
    },
    body: JSON.stringify(post)
  }).catch(error => {
    throw new Error();
  })

  return true;
  // console.log(r.body);
}

export const pushSuggestionUsers = async(uids: string[]) => {
  return await fetch("https://app.hamoni.jp/api/admin/suggestion", {
    method: "post",
    headers: {
      'Accept': 'application/json',
    },
    body: JSON.stringify({ uids })
  }).then(res => true).catch(e => false)
}

type Suggestion = {
  id: string; // doc id
  uid: string; // create uid
  toUids: string[]; // push users
  type: "post" | "bookmark"; // post or (bookmark)
  target: string; // postid / (bookmark id)
};

type HamoniApiSearchPostsResult = {
  status: 'success' | 'error';
  posts: HamoniApiPostResult[];
  message?: string;
};

type HamoniApiPostResult = {
  postid: string;
  shopid: string;
  create_at: string;
  distance: number;
  user: string; // User,
  shop: string; // Shop,
  post: string; // Post
};

export const getSuggestionUsers = async() => {
  const res = await fetch("https://app.hamoni.jp/api/suggestion")
  const result = (await res?.json()) as ( HamoniApiSearchPostsResult | undefined );

  const users = result?.posts
    .map((r) => JSON.parse(r.user) as User)
    .filter((u, i, us) => us.findIndex((_u) => u.uid == _u.uid) == i); // "UID" Filter
  const posts = result?.posts.map((r) => JSON.parse(r.post) as Post);
  const shops = result?.posts.map((r) => JSON.parse(r.shop) as Shop);

  return {
    users,
    posts,
    shops
  }
}