import React from "react";
import Header from "../component/Header";

const _getKey = () => {
  const chars = 'xxxxxxxxxxxxxxx'.split(''); // 15
  for (let i = 0, len = chars.length; i < len; i++)
    chars[i] = Math.floor(Math.random() * 16).toString(16);

  return 'k' + chars.join('');
};

const InstaLink: React.FC = () => {
  
  const [uid, setUid] = React.useState("")
  const [url, setUrl] = React.useState("")

  React.useEffect(() => {
    if(uid){
      const key = _getKey() // 乱数生成

      // リクエスト用のデータを作成
      const data = {
        uid,
        key,
      };
      // URIを生成する
      const uri = `https://app.hamoni.jp/api/insta/oauth?state=${encodeURI(
        JSON.stringify(data),
      )}`;
      setUrl(uri);
    }else{
      setUrl(""); // 無し
    }

  }, [uid])

  const copyClipBoardUrl = () => {
    if(typeof(window) !== undefined){
      window.navigator.clipboard.writeText(url)
        .then(r => alert("クリップボードにコピーしました。"))
        .catch(e => alert("クリップボードを使用できません。" + e))
    }
  }

  return (
    <article style={{ position: "relative" }}>
      <Header current="instalink" />
      <p style={{ fontSize: "small", padding: 5 }}>UIDを元に連携リンクを作成します。</p>
      <div style={{ padding: "0 10px" }}>
        <div style={{ marginTop: 8 }}>
          <label>UID</label>
          <input type="text" onChange={(e) => setUid(e.target.value)}/>
        </div>

        <p style={{padding: 5, borderBottom: "1px solid lightgray"}}></p>

        <div style={{ marginTop: 8 }}>
          <p>リンク</p>
          <input type="text" disabled value={url} style={{ minWidth: 320 }}/>
          <input type="button" value={"Copy"} onClick={copyClipBoardUrl}/>
          <p>
          { url ? <a href={url} >{url}</a> : <div/> }
          </p>
        </div>

        <p style={{padding: 5, borderBottom: "1px solid lightgray"}}></p>

        <div style={{ marginTop: 8 }}>
          <p>※ユーザーページ</p>
          { uid ? <a href={`https://app.hamoni.jp/user/${uid}`} >{`https://app.hamoni.jp/user/${uid}`}</a> : <div/>}
        </div>


      </div>
    </article>
  );
};

export default InstaLink;
