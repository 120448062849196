import React from "react";
import Header from "../component/Header";

const UserPage: React.FC = () => {
  
  const [isBusy, setIsBusy] = React.useState(false)
  const [displayId, setDisplayId] = React.useState("")
  const [user, setUser] = React.useState<User | null>(null)

  const onPushSearch = () => {
    if(isBusy) return
    setIsBusy(true)
    getUserByDisplayID(displayId)
      .then(r => setUser(r?.data ?? null))
      .catch(e => null)
      .finally(() => setIsBusy(false))
  }

  const getUserByDisplayID = async (displayId: string) => {  
    return await fetch(`https://app.hamoni.jp/api/user/display_id/${displayId}`)
      .then(async r => r.ok ? await r.json() as APIResultBaseSingle<User> : null)
      .catch(e => { console.warn(e); return null })
  }
  
  const getFirebaseWebLink = (uid: string) => {
    return `https://console.firebase.google.com/project/hamoni-jp/firestore/data/~2Fappuser~2F%20${uid}?hl=ja`
  } 

  return (
    <article style={{ position: "relative" }}>
      <Header current="user" />
      <p style={{ fontSize: "small", padding: 5 }}>DisplayIdを元にユーザを検索します。</p>
      <div style={{ padding: "0 10px" }}>
        <div style={{ marginTop: 8 }}>
          <label>DisplayID</label>
          <input type="text" onChange={(e) => setDisplayId(e.target.value)}/>
          <input type="button" value={"検索"} onClick={onPushSearch}/>
        </div>

        <p style={{padding: 5, borderBottom: "1px solid lightgray"}}></p>

        <section style={{ padding: "10px 5px" }}>
          { isBusy ? <img src="/loading.svg"/> : <div />}
          { user ? 
              <div>
                <h2>User</h2>
                <table rules="all" style={{ border: "1px solid lightgray" }} >
                  <tbody>
                    <tr>
                      <td>プロフィール画像</td>
                      <td>
                        <img src={`https://cdn.hamoni.jp/hamoni/${user.photoURL}`} style={{ height: 52, width: 52, objectFit: "cover" }}/>
                      </td>
                    </tr>
                    <tr>
                      <td>ユーザーID (uid)</td>
                      <td>{user.uid}</td>
                    </tr>
                    <tr>
                      <td>ユーザー名</td>
                      <td>{user.displayName}</td>
                    </tr>
                  </tbody>
                </table>
                <h3>Others</h3>
                <table>
                  <tbody>
                    <tr>
                      <td>投稿数</td>
                      <td>{user.post}</td>
                    </tr>
                    <tr>
                      <td>フォロー</td>
                      <td>{user.follow}</td>
                    </tr>
                    <tr>
                      <td>フォロワー</td>
                      <td>{user.follower}</td>
                    </tr>
                    <tr>
                      <td>連携済みSHOP (shopid)</td>
                      <td>{user.shopid ?? "None"}</td>
                    </tr>
                  </tbody>
                </table>

                <h3>その他</h3>
                <p style={{ fontSize: "small", color: "orange" }} >Firebase情報 ！アカウント情報のため、編集注意！ ※作業前に確認</p>
                <a style={{ fontSize: "6px" }} href={getFirebaseWebLink(user.uid)}>{getFirebaseWebLink(user.uid)}</a>
              </div> 
            : <p>Not Found</p>
          }
        </section>

      </div>
    </article>
  );
};

export default UserPage;
