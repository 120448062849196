import React from "react";
import Header from "./component/Header";
import { getSuggestionUsers, pushSuggestionUsers } from "./service/HAdminService";

const SuggestionEditor: React.FC = () => {

  const [isBusy, setIsBusy] = React.useState(true)
  const [users, setUsers] = React.useState<User[]>([])
  const [value, setValue] = React.useState("");

  React.useEffect(()=>{
    getSuggestionUsers().then(r => {
      setUsers(r?.users?.filter(u => u.uid) ?? []);
      setIsBusy(false);
    })
  }, [])

  React.useEffect(()=>{
    setValue(JSON.stringify(users.map(u => u.uid)))
  }, [users])

  const onPushOK = async () => {
    if(isBusy) return;
    try{
      const data = JSON.parse(value) as string[]
      if(!data?.[0] || data.find(d => d.length != 28)) alert("入力された数値が不正です。")
      else {
        const r = await pushSuggestionUsers(data).catch(e => null);
        if(r) alert("ok: " + data.length);
        else alert("missing");
      }
    }catch(e){
      alert("形式が不正です。")
    }
    setIsBusy(false);
  }

  return (
    <article style={{ position: "relative" }}>
      <Header current="suggestion" />
      <div style={{ position: "absolute", top: 30, left: 0, width: "100%", height: "calc(100% - 30px)", backgroundColor: "black", display: isBusy ? "flex" : "none", opacity: .5, justifyContent: "center", alignItems: "center"}}>
        <p style={{ color: "white" }}>Loading...</p>
      </div>
      <textarea style={{ width: 400, height: 300, margin: 10}} value={value} onChange={e => setValue(e.target.value)}/>
      <input onClick={onPushOK} type="submit" value="ok" />
    </article>
  )
};

export default SuggestionEditor;
