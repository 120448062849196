const levenshteinDistance = (str1: string, str2: string): number => {
	var x = str1.length;
	var y = str2.length;

	var d: number[][] = [];
	for (let i = 0; i <= x; i++) {
		d[i] = [];
		d[i][0] = i;
	}
	for (let i = 0; i <= y; i++) {
		d[0][i] = i;
	}

	var cost = 0;
	for (let i = 1; i <= x; i++) {
		for (var j = 1; j <= y; j++) {
			cost = str1[i - 1] == str2[j - 1] ? 0 : 1;

			d[i][j] = Math.min(d[i - 1][j] + 1, d[i][j - 1] + 1, d[i - 1][j - 1] + cost);
		}
	}
	return d[x][y];
}

export const getStrMatchRate = (str1: string, str2: string) => {
	const mc = Math.max(str1.length, str2.length);
	const ld = levenshteinDistance(replaceFullToHalf(str1), replaceFullToHalf(str2));

	return (mc - ld) / mc * 100;
}

/**
 * 全角文字を半角文字に変換。ついでに、諸々処理
 * @param strVal 
 */
export const replaceFullToHalf = (strVal: string) => {
  // 半角変換
  var halfVal = strVal.replace(/[！-～]/g,
    function( tmpStr ) {
      return String.fromCharCode( tmpStr.charCodeAt(0) - 0xFEE0 );
    }
  );
 
  return halfVal.replace(/”/g, "\"")
    .replaceAll(/’/g, "'")
    .replaceAll(/‘/g, "`")
    .replaceAll(/￥/g, "\\")
    .replaceAll(/　/g, " ")
    .replaceAll(/ /g, "")
    .replaceAll(/ー/g, "-")
		.replaceAll(/〜/g, "~")
		.replace('日本', '')
		.replace('、', '')
		.replace('、', '')
		.replace(/〒[0-9]{3}-[0-9]{4}/, '')
}