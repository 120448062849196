/**
 *  Firefunction/ Hamoni AdminAPI (サーバーから呼び出し専用API)
 *  と通信する際の認証付きfetch通信
 *  ※ 指定したトークンを追加して通信を行う。
 */

// 認証用トークン
const HADMIN_AUTH_TOKEN = 'RhhqpYc9WREp';

/** admin認証済みとしてAPIにFetchを行います。 */
export const fetchOnAdmin = (input: RequestInfo | URL, init: RequestInit) => {
  const headers = new Headers(init.headers);
  if (headers.get('authorization')) {
    // 既に対象ヘッダーがある場合、置き換えるが、警告を表示
    console.warn(
      'this fetch data already include auth header. this data will be replace.',
    );
  }
  headers.set('authorization', `Bearer ${HADMIN_AUTH_TOKEN}`);
  return fetch(input, { ...init, headers });
};
