export type HeaderType = "home" | "suggestion" | "embeds" | "instalink" | "insta_direct_link" | "instadetail" | "shop" | "user";

type Props = {
  current: HeaderType;
};

const Header: React.FC<Props> = ({ current }) => {
  // const items: HeaderType[] = ["home", "suggestion"];

  // const renderItem = (item: HeaderType, key: number) => {
  //     return <a key={key} href={item == "home" ? "/" : `/${item}`} style={{ fontWeight: item == current ? "bold" : "normal" }}>{item}</a>
  // }

  return (
    <header
      style={{
        display: "flex",
        borderBottom: "1px solid gray",
        fontSize: "x-small",
        height: 30,
        alignItems: "center",
      }}
    >
      {/* {items.map((item, ix) => renderItem(item, ix))} */}
      <a
        href="/"
        style={{
          margin: 8,
          fontWeight: current == "home" ? "bold" : "normal",
        }}
      >
        Home
      </a>
      <a
        href="/suggestion"
        style={{
          margin: 8,
          fontWeight: current == "suggestion" ? "bold" : "normal",
        }}
      >
        Suggestion
      </a>
      <a
        href="/embeds"
        style={{
          margin: 8,
          fontWeight: current == "embeds" ? "bold" : "normal",
        }}
      >
        Embeds
      </a>

      <a
        href="/insta_direct_link"
        style={{
          margin: 8,
          fontWeight: current == "insta_direct_link" ? "bold" : "normal",
        }}
      >
        instaDirectLink
      </a>

      <a
        href="/instalink"
        style={{
          margin: 8,
          fontWeight: current == "instalink" ? "bold" : "normal",
        }}
      >
        instalink
      </a>

      <a
        href="/instadetail"
        style={{
          margin: 8,
          fontWeight: current == "instadetail" ? "bold" : "normal",
        }}
      >
        instaDetail
      </a>

      <a
        href="/shop"
        style={{
          margin: 8,
          fontWeight: current == "shop" ? "bold" : "normal",
        }}
      >
        Shop
      </a>

      <a
        href="/user"
        style={{
          margin: 8,
          fontWeight: current == "user" ? "bold" : "normal",
        }}
      >
        User
      </a>

      <a
        href="/shortlink"
        style={{
          margin: 8,
          fontWeight: current == "user" ? "bold" : "normal",
        }}
      >
        ShortLink
      </a>

      <div style={{ flex: 1 }} />
      <p style={{ fontSize: "x-small", margin: 8, color: "lightgray" }}>
        Version: 0.3.3
      </p>
    </header>
  );
};

export default Header;
