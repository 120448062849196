import React from "react";
import Header from "../component/Header";

const _getKey = () => {
  const chars = 'xxxxxxxxxxxxxxx'.split(''); // 15
  for (let i = 0, len = chars.length; i < len; i++)
    chars[i] = Math.floor(Math.random() * 16).toString(16);

  return 'k' + chars.join('');
};

const ShortLink: React.FC = () => {
  
  const [shortLink, setShortLink] = React.useState<string>()
  const [url, setUrl] = React.useState("https://")
  const [args, setArgs] = React.useState("")

  React.useEffect(() => {
    try{
      const u = new URL(url)
      const redirect = url // encodeURIComponent(url)

      // 簡易セキュリティ: リンク内にhamoniがないと飛ばせなくなってる
      // if(!`${u.origin}${u.pathname}`.includes("hamoni")) 
      //   throw new Error("Not include [hamoni] in link")    

      const linkUrl = new URL("https://app.hamoni.jp/link/shortlink")
      linkUrl.searchParams.set("redirect", redirect)
      if(args) linkUrl.searchParams.set("args", `${args}`)

      setShortLink(linkUrl.href)
    }catch{
      setShortLink("")
    }
  }, [url, args])

  const copyClipBoardUrl = () => {
    if(typeof(window) !== undefined && shortLink){
      window.navigator.clipboard.writeText(shortLink)
        .then(r => alert("クリップボードにコピーしました。" + shortLink))
        .catch(e => alert("クリップボードを使用できません。" + e))
    }
  }

  return (
    <article style={{ position: "relative" }}>
      <Header current="instalink" />
      <p style={{ fontSize: "small", padding: 5 }}>URLを元にトラッキングリンクを作成します。</p>
      <p style={{ fontSize: "small", padding: 5 }}>このリンク経由でアクセスすることでどのくらい開かれたか等確認できます。</p>

      <div style={{ padding: "0 10px" }}>
        <div style={{ marginTop: 8 }}>
          <label>遷移先URL (必須)</label>
          <input type="text" value={url} onChange={(e) => setUrl(e.target.value)}/>
        </div>

        <div style={{ marginTop: 4 }}>
          <label>追加パラメータ</label>
          <input type="text" value={args} onChange={(e) => setArgs(e.target.value)}/>
        </div>

        <p style={{padding: 5, borderBottom: "1px solid lightgray"}}></p>

        <div style={{ marginTop: 8 }}>
          <p>リンク</p>
          <input type="text" disabled value={shortLink} style={{ minWidth: 320 }}/>
          <input type="button" value={"Copy"} onClick={copyClipBoardUrl}/>
          <p>
          { url ? <a href={shortLink} >{shortLink}</a> : <div/> }
          </p>
        </div>

        <p style={{ fontSize: "x-small", padding: 5, color: "gray" }}>
          ※リンクが表示されない場合、正しくないフォーマットの可能性があります。<br/>
          ※簡易セキュリティでURLにhamoniが含まれる場合、飛ばせるようにしています。
        </p>

      </div>
    </article>
  );
};

export default ShortLink;
