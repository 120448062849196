/**
 *  Admin用API (firebase/adminapi) 操作用サービス
 *  管理者権限としてリクエストを投げます。
 */

import { fetchOnAdmin } from "../utils/admin-fetch-utils"

const FIREBASE_API_ENDPOINT = "https://asia-northeast1-hamoni-jp.cloudfunctions.net"

export const createShopRequest = async (req: CreateNewShopRequest) => {
    return await fetchOnAdmin(`${FIREBASE_API_ENDPOINT}/admin_api-createNewShop`, {
        method: "POST",
        headers: {
            "Content-Type" : "application/json"
        },
        body: JSON.stringify(req)
    })
    .then(async r => await r.json() as CreateNewShopResponse)
    .catch(e => {
        console.warn(e)
        return null
    })
}