import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import SuggestionEditor from "./SuggestionEditor";
import Embed from "./pages/Embed";
import InstaLink from "./pages/InstaLink";
import "./App.css";
import User from "./pages/User";
import Shop from "./pages/Shop"
import InstaDetail from "./pages/InstaDetail";
import ShortLink from "./pages/ShortLink";
import InstaDirectToken from "./pages/InstaDirectToken";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/user" element={<User />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/suggestion" element={<SuggestionEditor />} />
        <Route path="/embeds" element={<Embed />} />
        <Route path="/instalink" element={<InstaLink />} />
        <Route path="/insta_direct_link" element={<InstaDirectToken />} />
        <Route path="/instadetail" element={<InstaDetail />} />
        <Route path="/shortlink" element={<ShortLink />} />
        <Route path="" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
